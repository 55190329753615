import 'script-loader!.tmp/js/templates/location/modules/ArticlesV2.js';

/**
 * @returns {Promise<Object>}
 */
export async function fetchArticlesForTemplate() {
  const employeeId = Yext.Eid;
  const apiURL = `https://external-prod.sprinklr.com/rest/yext/getArticles/782/${employeeId}`;

  try {
    const response = await fetch(apiURL);
    if (!response.ok) {
      throw new Error(`API Error: ${response.statusText}`);
    }
    const articles = await response.json();
    const templateVariables = prepareTemplateVariables(articles);
    const wrapper = document.querySelector('.js-articles-wrapper');
    if (wrapper) {
      wrapper.innerHTML = location.articlesV2(templateVariables);
    }
    
  } catch (error) {
    console.error('Failed to fetch articles:', error);
  }
}

/**
 * @param {Array} articles
 * @returns {Object}
 */
function prepareTemplateVariables(articles) {
  if (!articles || !articles.length) {
    return { hasArticles: false, articles: [] };
  }

  const formattedArticles = articles.map(article => {
    const post = article.type_details.post;

    if (!post || !post.image || !post.title || !post.description || !post.url || !post.createDate) {
      return null;
    }

    if (!post.url.startsWith('http://') && !post.url.startsWith('https://')) {
      post.url = 'https://' + post.url;
    }

    return {
      imageUrl: post.image,
      title: post.title,
      description: trimText(post.description, 30),
      url: post.url,
      createDate: formatCreateDate(post.createDate),
    };
  }).filter(article => article !== null).slice(0, 3);

  return {
    name: Yext.Ename,
    hasArticles: formattedArticles.length > 0,
    articles: formattedArticles,
  };
}


/**
 * @param {string} title
 * @param {number} maxLength
 * @returns {string}
 */
function trimText(title, maxLength = 30) {
  if (!title) return '';
  return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
}

/**
 * @param {number|string} date
 * @returns {string}
 */
function formatCreateDate(date) {
  if (!date) return '';
  const timestamp = typeof date === 'string' ? parseInt(date) : date;
  const parsedDate = new Date(timestamp);
  if (isNaN(parsedDate)) {
    return '';
  }
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  const formattedDate = parsedDate.toLocaleDateString('en-US', options);
  return formattedDate;
}
