window.Yext = window.Yext || {};
import 'babel-polyfill';
import 'script-loader!node_modules/jquery/dist/jquery.min.js';
import 'script-loader!node_modules/slick-carousel/slick/slick.js';

// uncomment if you need to do expand/collapse:
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/collapse.js';
// import 'node_modules/bootstrap-sass/assets/javascripts/bootstrap/transition.js';

import { Global } from 'js/common/global.js';
import { OnReady } from 'js/components/Util/Browser.js';
import { InitSoy2JS } from 'js/components/Soy2js/Soy2js.js';
import { LocationHours } from 'js/components/LocationHours/index.js';
import CitizensAbodeTagger from 'js/common/modules/CitizensAdobeTagger.js';
import NearbyBranches from 'js/location/modules/NearbyBranches.js';
import Calculator from 'js/location/modules/Calculator.js';
import { ImageCarousel } from 'js/location/modules/PromoCarousel.js';
import { Loader } from 'js/components/YoutubeEmbed/index.js';
import { ImageObjectFit } from 'js/components/Image/index.js';
import { ArticlesHearsay } from 'js/location/modules/ArticlesHearsay.js';
import { Superscripts } from 'js/common/modules/Superscripts.js';
import { fetchArticlesForTemplate } from 'js/location/modules/ArticlesFetcher.js';

InitSoy2JS(window.Yext);

Global.init();

OnReady(() => {
  LocationHours.loadAndRun();
  CitizensAbodeTagger(true, 'loanofficer');
  Loader.load();
  new ArticlesHearsay(() => {
    ImageObjectFit();
  });

  const youtubeCarousel = $('.youtube-carousel');
  if (youtubeCarousel) {
    youtubeCarousel.slick({
      cssEase: 'ease-in-out',
      fade: false,
      infinite: false,
      appendDots: $(".youtube-dots"),
      prevArrow: $(".youtube-prevArrow"),
      nextArrow: $(".youtube-nextArrow"),
      slidesToShow: 2,
      dots: true,
      rows: 0
    });
  }

  // - The un-minified file is different than the minified one :/
  // - Uncomment the following if using JVector map
  // VectorMap.autorun();
  ImageObjectFit();
  NearbyBranches.init();
  Calculator.init();
  new ImageCarousel();
  Superscripts.run('.Event-body');

  // Lazy load fetchArticlesForTemplate when scrolling to the section
  const targetElement = document.querySelector('.js-articles-wrapper');
  fetchArticlesForTemplate();
});
